import React from "react"
import styled from "styled-components"

const StyledSummaryContainer = styled.div`
  border-inline: 1px solid #000;
  padding: 0.5rem 1rem;
  position: relative;
  ::before,
  ::after {
    content: "";
    width: 10px;
    height: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  ::before {
    border-inline-start: 2px solid #000;
    border-block: 2px solid #000;
    left: 0;
  }
  ::after {
    border-inline-end: 2px solid #000;
    border-block: 2px solid #000;
    right: 0;
  }
`

const StyledParagraph = styled.p`
  line-height: 1.32em;
`

const StyledNotification = styled.p`
  line-height: 1.2em;
  font-size: 0.8rem;
  padding-inline: 1rem;
  :first-of-type {
    margin-block-start: 0.5rem;
  }
  :last-of-type {
    margin-block-end: 3rem;
  }
`

export default function Summary({
  paragraph1,
  paragraph2,
  paragraph3,
  notification = [],
}) {
  return (
    <>
      <StyledSummaryContainer>
        <StyledParagraph>{paragraph1}</StyledParagraph>
        <StyledParagraph>{paragraph2}</StyledParagraph>
        <StyledParagraph>{paragraph3}</StyledParagraph>
      </StyledSummaryContainer>
      <StyledNotification>{notification[0]}</StyledNotification>
      <StyledNotification>{notification[1]}</StyledNotification>
    </>
  )
}
