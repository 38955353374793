import React from "react"
import styled, { css } from "styled-components"
import { media } from "../../styles/media"

//gatsby
import { useStaticQuery, graphql } from "gatsby"

//component
import Layout from "components/Layout"
import ThirdLayerHeader from "components/ThirdLayerHeader"
import ThirdLayerClose from "components/ThirdLayerClose"
import { ThirdLayer, Main } from "components/ThirdLayerBody"
import SwipeMotionDiv from "components/ui/SwipeMotionDiv"
import SEO from "../../components/SEO"
import { Report } from "../../features/report-2023-08"

export default function Report202308(props) {
  const {
    data: { report202308: report },
    seo,
  } = useStaticQuery(query)
  const { location, transitionStatus, entry, exit } = props
  const parentPath = "/" + location.pathname.split("/")[1]
  console.log("data", report)
  const jsonld = [
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          item: {
            "@id": `${seo.top.pass}`,
            name: `${seo.top.title}`,
          },
        },
        {
          "@type": "ListItem",
          position: 2,
          item: {
            "@id": `${seo.page.labo.pass}`,
            name: `${seo.page.labo.title}`,
          },
        },
        {
          "@type": "ListItem",
          position: 3,
          item: {
            "@id": `${seo.page.labo.varietyFiber.pass}`,
            name: `${seo.page.labo.varietyFiber.title}`,
          },
        },
      ],
    },
  ]

  return (
    <ThirdLayer>
      <Layout>
        {/* <SEO
          title={data.seo.page.labo.varietyFiber.title}
          pageUrl={data.seo.page.labo.varietyFiber.pass}
          description={data.seo.page.labo.varietyFiber.description}
          imgUrl="/mainVisual.jpg"
          type="article"
          jsonld={jsonld}
        /> */}
        <SwipeMotionDiv
          entry={entry}
          exit={exit}
          transitionStatus={transitionStatus}
        >
          {/* <ThirdLayerHeader
            text={data.text.report202308.leadText}
            parentPath={parentPath}
          /> */}
          {/* <Header text={report.leadText} parentPath={parentPath} /> */}
          <Report data={report} />
          <ThirdLayerClose parentPath={parentPath} />
        </SwipeMotionDiv>
      </Layout>
    </ThirdLayer>
  )
}

export const query = graphql`
  {
    data: pagesYaml(pageName: { eq: "laboPage" }) {
      report202308 {
        timestamp
        leadText
        paragraph1
        paragraph2
        paragraph3
        notification
      }
    }

    seo: dataYaml {
      top {
        description
        pass
        title
      }
      page {
        labo {
          description
          pass
          title
          varietyFiber {
            pass
            description
            title
          }
        }
      }
    }
  }
`
