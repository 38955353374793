import React from "react"
import Summary from "./Summary"
import Images from "./Images"
import styled from "styled-components"
import Title from "./Title"

const StyledContentContainer = styled.div`
  max-width: 800px;
  padding-inline: 16px;
  margin-block-end: 1.62em;
  margin-inline: auto;
`
export default function Report({ data }) {
  return (
    <StyledContentContainer>
      <Title text={data.leadText} timestamp={data.timestamp} />
      <Summary {...data} />
      <Images />
    </StyledContentContainer>
  )
}
