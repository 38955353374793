import React from "react"
import styled, { css } from "styled-components"
// import TransitionLink from "gatsby-plugin-transition-link"
// import { setSwipeState } from "../../../lib/setTransitionLinkState"
import { media } from "../../../styles/media"
// import { FlexCenter } from "../../../components/CssTools"

const StyledWrap = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-block: 3rem 2rem;
  h2 {
    line-height: 1.32em;
    font-weight: 700;
  }
  ${() =>
    media.tablet(css`
      max-width: 800px;
      /* margin: 0 auto; */
    `)}
`
// const StyledCross = styled(TransitionLink)`
//   cursor: pointer;
//   width: 45px;
//   height: 45px;
//   ${FlexCenter}
//   flex-flow:row nowrap;
//   position: relative;
//   span {
//     width: 24px;
//     height: 3px;
//     background: #292929;
//     border-radius: 10px;
//     position: absolute;
//     display: block;
//     content: "";
//     &:first-child {
//       transform: rotate(45deg);
//     }
//     &:nth-child(2) {
//       transform: rotate(-45deg);
//     }
//   }
// `

export default function Title({ text, timestamp }) {
  return (
    <StyledWrap>
      <p>{timestamp}</p>
      <h2>{text}</h2>
      {/* <StyledCross
        to={parentPath + "/"}
        exit={setSwipeState(
          typeof window !== "undefined" && window.innerWidth,
          "EXIT",
          "CLOSE"
        )}
        entry={setSwipeState(
          typeof window !== "undefined" && window.innerWidth,
          "ENTRY",
          "CLOSE"
        )}
      >
        <span></span>
        <span></span>
      </StyledCross> */}
    </StyledWrap>
  )
}
