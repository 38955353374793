import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const StyledImagesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 2%;
  .image-container {
    width: 50%;
  }
`

const StyledImageContainer = styled.div`
  position: relative;
  width: 50%;
  .soygood-logo-container {
    width: 10%;
    background-color: white;
    border-radius: 50%;
    /* padding: 24px; */
    padding: 10%;
    position: absolute !important;
    bottom: 10%;
    right: 4%;
    .soygood-logo-img {
      /* width: 20px;
      height: 20px; */
      width: 40%;
      height: 40%;
      margin: auto;
    }
  }
  .inulia-logo-container {
    width: 40%;
    position: absolute !important;
    bottom: 0;
    right: -4%;
  }
`

export default function Images() {
  return (
    <StyledImagesContainer>
      <StyledImageContainer>
        <StaticImage src="../../../images/na_soybean.jpg" alt="soybean" />
        <StaticImage
          src="../../../images/logo-soygood.svg"
          alt="soygood"
          className="soygood-logo-container"
          imgClassName="soygood-logo-img"
        />
      </StyledImageContainer>
      <StyledImageContainer>
        <StaticImage
          src="../../../images/na_chicory_field.jpg"
          alt="chicory field"
        />
        <StaticImage
          src="../../../images/inulogo_with_bg.png"
          alt="inulia logo"
          className="inulia-logo-container"
        />
      </StyledImageContainer>
    </StyledImagesContainer>
  )
}
